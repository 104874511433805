export default {
  BUTTON_PRIMARY_CLASS: 'cm_button cm_button__primary',
  BUTTON_SECONDARY_CLASS: 'cm_button cm_button__secondary',
  SEARCH_BOX_BUTTON_CLASS: 'cm_button cm_button__primary',

  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
